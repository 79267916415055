<template class="mr-3">
  <div class="card">
    <div class="card-body">
      <div>
        <a-button
          type="file-pdf"
          @click="generatePdf"
          style="margin-bottom: 20px; float: right"
        >
          <a-icon type="file-pdf" @click="generatePdf" />
          {{ $t("action.imprimerTab") }}
        </a-button>
        <a-button type="user-add" @click="showModal">
          <a-icon type="user-add" @click="showModal" />
          {{ $t("action.ajouter") }}
          {{ $t("menu.personnel") }}
        </a-button>
        <div>
          <a-modal
            v-model="visibleData"
            @cancel="handleCancel"
            :width="980"
            :title="$t('all.enterInformationBelow')"
            :footer="false"
          >
            <a-form :form="form" class="container" @submit="addPersonnel">
              <fieldset>
                <legend>{{ $t("personnel.infoBase") }}:</legend>
                <div class="row">
                  <div class="col-md-4">
                    <a-form-item :label="$t('profil.nom')">
                      <a-input
                        :placeholder="$t('profil.nom')"
                        v-decorator="[
                          'lastName',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.nom'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('profil.prenom')">
                      <a-input
                        :placeholder="$t('profil.prenom')"
                        v-decorator="[
                          'firstName',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.prenomPere'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('profil.dateNaissance')">
                      <a-date-picker
                        v-decorator="['birthDate']"
                        :format="dateFormat"
                        :getCalendarContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      />
                    </a-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.cin')">
                      <a-input
                        :placeholder="$t('personnel.cin')"
                        type="number"
                        v-decorator="[
                          'cin',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.cin'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('profil.adresse')">
                      <a-input
                        :placeholder="$t('profil.adresse')"
                        v-decorator="['adress']"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('liste.telephone')">
                      <a-input
                        :placeholder="$t('liste.telephone')"
                        type="number"
                        v-decorator="[
                          'phone',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.telephone'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.etatCivil')">
                      <a-input
                        :placeholder="$t('personnel.etatCivil')"
                        v-decorator="['civilStatus']"
                      />
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $t("personnel.niveau") }}:</legend>
                <div class="row">
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.niveau')">
                      <a-input
                        :placeholder="$t('personnel.niveau')"
                        v-decorator="['level']"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-8">
                    <a-form-item :label="$t('personnel.experiences')">
                      <a-input
                        :placeholder="$t('personnel.experiences')"
                        type="textarea"
                        v-decorator="[
                          'experiences',
                          {
                            rules: [
                              {
                                required: false,
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $t("personnel.infoContrat") }}</legend>
                <div class="row">
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.qualification')">
                      <a-select
                        v-decorator="[
                          'position',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.qualification'),
                              },
                            ],
                          },
                        ]"
                        show-search
                        :allowClear="true"
                        :placeholder="$t('personnel.qualification')"
                        style="width: 100%"
                        option-filter-prop="children"
                        :filter-option="filterOption"
                        :getPopupContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      >
                        <a-select-option
                          v-for="position in positionsList"
                          :key="position._id"
                          :value="position._id"
                          >{{ position.name }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.typeContrat')">
                      <a-input
                        :placeholder="$t('personnel.typeContrat')"
                        v-decorator="['contract']"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.salaire') + ' (net)'">
                      <a-input
                        :placeholder="$t('personnel.salaire') + ' (net)'"
                        type="number"
                        v-decorator="['salary.net']"
                      />
                    </a-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.dateEntretien')">
                      <a-date-picker
                        v-decorator="['interviewDate']"
                        :format="dateFormat"
                        :getCalendarContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('all.startDate')">
                      <a-date-picker
                        v-decorator="['StartDate']"
                        :format="dateFormat"
                        :getCalendarContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('all.endDate')">
                      <a-date-picker
                        v-decorator="['endDate']"
                        :format="dateFormat"
                        :getCalendarContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      />
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $t("personnel.detailPaiement") }}</legend>
                <div class="row">
                  <div class="col-md-3">
                    <a-form-item :label="$t('personnel.tauxHoraire')">
                      <a-input
                        :placeholder="$t('personnel.tauxHoraire')"
                        type="number"
                        v-decorator="[
                          'hourlyRate',
                          {
                            initialValue: 0,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-3">
                    <a-form-item :label="$t('personnel.jourSemaine')">
                      <a-input
                        :placeholder="$t('personnel.jourSemaine')"
                        type="number"
                        v-decorator="[
                          'dayOfWeek',
                          {
                            initialValue: 0,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-3">
                    <a-form-item :label="$t('personnel.cnss')">
                      <a-input
                        :placeholder="$t('personnel.cnss')"
                        type="number"
                        v-decorator="[
                          'CNSS',
                          {
                            initialValue: 0,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-3">
                    <a-form-item :label="$t('personnel.rib')">
                      <a-input
                        :placeholder="$t('personnel.rib')"
                        v-decorator="[
                          'RIB',
                          {
                            initialValue: '0',
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $t("personnel.autre") }}:</legend>
                <div class="row">
                  <a-form-item :label="$t('profil.notes')" class="col-md-12">
                    <a-input
                      :placeholder="$t('profil.notes')"
                      type="textarea"
                      v-decorator="['notes']"
                    />
                  </a-form-item>
                </div>
              </fieldset>
              <div class="form-actions mt-0 row">
                <div class="col-md-2 offset-md-8 p-0">
                  <a-button key="back" @click="handleCancel">{{
                    $t("action.annuler")
                  }}</a-button>
                </div>
                <div class="col-md-2 p-0">
                  <a-button
                    type="primary"
                    htmlType="submit"
                    :loading="loadingAddBtn"
                    :disabled="loadingAddBtn"
                    >{{ $t("action.ajouter") }}</a-button
                  >
                </div>
              </div>
            </a-form>
          </a-modal>
          <a-modal
            v-model="visibleUpdateData"
            @cancel="handleCancel"
            :width="980"
            :title="$t('all.enterInformationBelow')"
            :footer="false"
          >
            <a-form
              :form="updateForm"
              class="container"
              @submit="updatePersonnel"
            >
              <fieldset>
                <legend>{{ $t("personnel.infoBase") }}:</legend>
                <div class="row">
                  <div class="col-md-4">
                    <a-form-item :label="$t('profil.prenom')">
                      <a-input
                        :placeholder="$t('profil.prenom')"
                        v-decorator="[
                          'firstName',
                          {
                            initialValue: updateData.firstName,
                            rules: [
                              {
                                required: true,
                                message: $t('requis.prenomPere'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('profil.nom')">
                      <a-input
                        :placeholder="$t('profil.nom')"
                        v-decorator="[
                          'lastName',
                          {
                            initialValue: updateData.lastName,
                            rules: [
                              {
                                required: true,
                                message: $t('requis.nom'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('profil.dateNaissance')">
                      <a-date-picker
                        v-decorator="[
                          'birthDate',
                          {
                            initialValue: updateData.birthDate,
                          },
                        ]"
                        :format="dateFormat"
                        :getCalendarContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      />
                    </a-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.cin')">
                      <a-input
                        :placeholder="$t('personnel.cin')"
                        type="number"
                        v-decorator="[
                          'cin',
                          {
                            initialValue: updateData.cin,
                            rules: [
                              {
                                required: true,
                                message: $t('requis.cin'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('profil.adresse')">
                      <a-input
                        :placeholder="$t('profil.adresse')"
                        v-decorator="[
                          'adress',
                          {
                            initialValue: updateData.adress,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('liste.telephone')">
                      <a-input
                        :placeholder="$t('liste.telephone')"
                        type="number"
                        v-decorator="[
                          'phone',
                          {
                            initialValue: updateData.phone,
                            rules: [
                              {
                                required: true,
                                message: $t('requis.telephone'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.etatCivil')">
                      <a-input
                        :placeholder="$t('personnel.etatCivil')"
                        v-decorator="[
                          'civilStatus',
                          {
                            initialValue: updateData.civilStatus,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $t("personnel.niveau") }}:</legend>
                <div class="row">
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.niveau')">
                      <a-input
                        :placeholder="$t('personnel.niveau')"
                        v-decorator="[
                          'level',
                          {
                            initialValue: updateData.level,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-8">
                    <a-form-item :label="$t('personnel.experiences')">
                      <a-input
                        :placeholder="$t('personnel.experiences')"
                        type="textarea"
                        v-decorator="[
                          'experiences',
                          {
                            initialValue: updateData.experiences,
                            rules: [
                              {
                                required: false,
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $t("personnel.infoContrat") }}:</legend>
                <div class="row">
                  <div class="col-md-4" v-if="!updateData.isA_Teacher">
                    <a-form-item :label="$t('personnel.qualification')">
                      <a-select
                        v-decorator="[
                          'position',
                          {
                            initialValue: updateData.position
                              ? updateData.position._id
                              : '',
                            rules: [
                              {
                                required: true,
                                message: $t('requis.qualification'),
                              },
                            ],
                          },
                        ]"
                        show-search
                        :allowClear="true"
                        :placeholder="$t('personnel.qualification')"
                        style="width: 100%"
                        option-filter-prop="children"
                        :filter-option="filterOption"
                        :getPopupContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      >
                        <a-select-option
                          v-for="position in positionsList"
                          :key="position._id"
                          :value="position._id"
                          >{{ position.name }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.typeContrat')">
                      <a-input
                        :placeholder="$t('personnel.typeContrat')"
                        v-decorator="[
                          'contract',
                          {
                            initialValue: updateData.contract,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.salaire') + ' (net)'">
                      <a-input
                        :placeholder="$t('personnel.salaire') + ' (net)'"
                        type="number"
                        v-decorator="[
                          'salary.net',
                          {
                            initialValue:
                              updateData.salary && updateData.salary.net
                                ? updateData.salary.net
                                : null,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <a-form-item :label="$t('personnel.dateEntretien')">
                      <a-date-picker
                        v-decorator="[
                          'interviewDate',
                          {
                            initialValue: updateData.interviewDate,
                          },
                        ]"
                        :format="dateFormat"
                        :getCalendarContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('all.startDate')">
                      <a-date-picker
                        v-decorator="[
                          'StartDate',
                          {
                            initialValue: updateData.StartDate,
                          },
                        ]"
                        :format="dateFormat"
                        :getCalendarContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item :label="$t('all.endDate')">
                      <a-date-picker
                        v-decorator="[
                          'endDate',
                          {
                            initialValue: updateData.endDate
                              ? updateData.endDate
                              : null,
                          },
                        ]"
                        :format="dateFormat"
                        :getCalendarContainer="
                          (triggerNode) => triggerNode.parentNode
                        "
                      />
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $t("personnel.detailPaiement") }}:</legend>
                <div class="row">
                  <div class="col-md-3">
                    <a-form-item :label="$t('personnel.tauxHoraire')">
                      <a-input
                        :placeholder="$t('personnel.tauxHoraire')"
                        type="number"
                        v-decorator="[
                          'hourlyRate',
                          {
                            initialValue: updateData.hourlyRate,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-3">
                    <a-form-item :label="$t('personnel.jourSemaine')">
                      <a-input
                        :placeholder="$t('personnel.jourSemaine')"
                        type="number"
                        v-decorator="[
                          'dayOfWeek',
                          {
                            initialValue: updateData.dayOfWeek,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-3">
                    <a-form-item :label="$t('personnel.cnss')">
                      <a-input
                        :placeholder="$t('personnel.cnss')"
                        type="number"
                        v-decorator="[
                          'CNSS',
                          {
                            initialValue: updateData.CNSS,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-3">
                    <a-form-item :label="$t('personnel.rib')">
                      <a-input
                        :placeholder="$t('personnel.rib')"
                        type="number"
                        v-decorator="[
                          'RIB',
                          {
                            initialValue: updateData.RIB,
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $t("personnel.autre") }}:</legend>
                <div class="row">
                  <a-form-item :label="$t('profil.notes')" class="col-md-12">
                    <a-input
                      :placeholder="$t('profil.notes')"
                      type="textarea"
                      v-decorator="[
                        'notes',
                        {
                          initialValue: updateData.notes,
                        },
                      ]"
                    />
                  </a-form-item>
                </div>
              </fieldset>
              <div class="form-actions mt-0 row">
                <div class="col-md-2 offset-md-8 p-0">
                  <a-button key="back" @click="handleCancel">{{
                    $t("action.annuler")
                  }}</a-button>
                </div>
                <div class="col-md-2 p-0">
                  <a-button type="primary" htmlType="submit">{{
                    $t("action.modifier")
                  }}</a-button>
                </div>
              </div>
            </a-form>
          </a-modal>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-lg-2">
          <span class="text-muted">
            <i class="fa fa-briefcase text-success pr-1"></i>
            :
            {{ $t("menu.enseignants") }}
          </span>
        </div>
        <div class="col-lg-2">
          <span class="text-muted">
            <i class="fa fa-suitcase text-warning pr-1" />
            :
            {{ $t("personnel.staff") }}
          </span>
        </div>
      </div>
      <div>
        <a-table
          :pagination="true"
          :data-source="data"
          :loading="tableLoading"
          :columns="columns"
          rowKey="_id"
          :scroll="{ x: 'max-content' }"
        >
          <template slot="StartDate" slot-scope="text, record">{{
            moment(record.StartDate).format("DD/MM/YYYY")
          }}</template>
          <template slot="endDate" slot-scope="text, record">
            {{
              record.endDate
                ? moment(record.endDate).format("DD/MM/YYYY")
                : "Non précisé"
            }}
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="editable-row-operations">
              <span v-if="record.editable">
                <a @click="() => save(record.key)">{{ $t("all.save") }}</a>
                <a-popconfirm
                  title="Sure to cancel?"
                  @confirm="() => cancel(record.key)"
                >
                  <a>{{ $t("action.annuler") }}</a>
                </a-popconfirm>
              </span>
              <span v-else>
                <a-button
                  type="primary"
                  :disabled="editingKey !== ''"
                  @click="() => edit(record)"
                >
                  <a-icon
                    type="edit"
                    :disabled="editingKey !== ''"
                    @click="() => edit(record)"
                  />
                  {{ $t("action.modifier") }}
                </a-button>
              </span>
            </div>
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="$t('paiement.chercher') + ` ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <template slot="delete" slot-scope="text, record">
            <a-popconfirm
              title="Sure to delete?"
              @confirm="() => onDelete(record.key)"
            >
              <a-button type="danger">
                <a-icon type="delete" href="javascript:;" />
                {{ $t("action.supprimer") }}
              </a-button>
            </a-popconfirm>
          </template>
          <template slot="isTeacher" slot-scope="text, record">
            <i v-if="record.isA_Teacher" class="fa fa-briefcase text-success" />
            <i v-else class="fa fa-suitcase text-warning" />
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import $ from "jquery";
import apiClient from "@/services/axios";
import JsPDF from "jspdf";
import "jspdf-autotable";
import writtenNumber from "written-number";
import { mapState } from "vuex";
import moment from "moment";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  computed: mapState(["settings"]),
  async created() {
    this.tableLoading = true;
    await apiClient
      .post("/employees/filter", {
        status: "active",
      })
      .then((res) => {
        this.data = res.data.map((elem) => ({ ...elem, key: elem._id }));
      })
      .catch(() => this.$message.error("Aucun Personnel est trouvé"));
    this.cacheData = this.data.map((item) => ({ ...item }));
    apiClient
      .post("/positions/filter", { query: { status: "active" } })
      .then((res) => (this.positionsList = res.data))
      .catch(() => {});
    this.tableLoading = false;
  },
  data() {
    return {
      positionsList: [],
      dateFormat: "DD/MM/YYYY",
      editingKey: "",
      loadingAddBtn: false,
      tableLoading: false,
      visibleParentFile: false,
      data: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      previewVisible: false,
      previewImage: "",
      form: this.$form.createForm(this),
      updateForm: this.$form.createForm(this),
      updateData: {
        _id: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        cin: 0,
        adress: "",
        phone: 0,
        civilStatus: "",
        level: "",
        experiences: "",
        position: { _id: "" },
        contract: "",
        salary: { net: 0 },
        isA_Teacher: false,
        interviewDate: "",
        StartDate: "",
        endDate: "",
        hourlyRate: 0,
        dayOfWeek: 0,
        CNSS: 0,
        RIB: 0,
        notes: "",
      },
      value: 1,
      value1: 1,
      loadingPersonnel: false,
      visibleData: false,
      visibleUpdateData: false,
      columns: [
        {
          title: "",
          dataIndex: "isTeacher",
          scopedSlots: { customRender: "isTeacher" },
          fixed: "left",
        },
        {
          title: this.$t("personnel.prenom"),
          dataIndex: "firstName",
          key: "firstName",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "firstName",
          },
          onFilter: (value, record) =>
            record.firstName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("personnel.nom"),
          dataIndex: "lastName",
          key: "lastName",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "lastName",
          },
          onFilter: (value, record) =>
            record.lastName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("personnel.adresse"),
          dataIndex: "adress",
          key: "adress",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "adress",
          },
          onFilter: (value, record) =>
            record.adress.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("personnel.telephone"),
          dataIndex: "phone",
          key: "phone",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "phone",
          },
          onFilter: (value, record) =>
            record.phone.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("personnel.qualification"),
          dataIndex: "position.name",
          key: "qualification",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "qualification",
          },
          onFilter: (value, record) =>
            record.qualification.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("all.startDate"),
          dataIndex: "StartDate",
          key: "StartDate",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "StartDate",
          },
          onFilter: (value, record) =>
            record.StartDate.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("all.endDate"),
          dataIndex: "endDate",
          key: "endDate",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "endDate",
          },
          onFilter: (value, record) =>
            record.endDate.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("personnel.typeContrat"),
          dataIndex: "contract",
          key: "contract",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "contract",
          },
          onFilter: (value, record) =>
            record.contract.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("personnel.salaire"),
          dataIndex: "salary.net",
          key: "salary",
          width: "auto",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "salary",
          },
          onFilter: (value, record) =>
            record.salary.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "delete",
          scopedSlots: { customRender: "delete" },
          fixed: "right",
        },
      ],
    };
  },
  methods: {
    moment,
    updatePersonnel(e) {
      e.preventDefault();
      this.updateForm.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const id = this.updateData._id;
          apiClient
            .patch(`/employees/${id}`, values)
            .then((res) => {
              this.data = this.data.map((elem) =>
                elem._id == id
                  ? {
                      ...values,
                      _id: id,
                      position: { _id: values.position },
                      isA_Teacher: elem.isA_Teacher,
                    }
                  : elem
              );
              this.$message.success(this.$t("success.personnelMSA"));
              this.updateForm.resetFields();
              this.visibleUpdateData = false;
            })
            .catch(() => this.$message.error(this.$t("error.personnelNonMSA")));
        }
      });
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(record) {
      console.log(record);
      this.updateData = record;
      this.visibleUpdateData = true;
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = "";
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    onDelete(key) {
      apiClient
        .patch("/employees/" + key, {
          status: "inactive",
        })
        .then(() => {
          this.$message.success(this.$t("success.personnelSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch(() =>
          this.$message.success(this.$t("success.personnelNonSupp"))
        );
    },
    addPersonnel(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingAddBtn = true;

          apiClient
            .put("/employees", values)
            .then((res) => {
              this.data.push({ ...res.data, key: res.data._id });
              this.$message.success(this.$t("success.personnelAjout"));
              this.handleCancel();
            })
            .catch(() =>
              this.$message.error(this.$t("error.personnelNonAjoute"))
            )
            .finally(() => (this.loadingAddBtn = false));
        }
      });
    },
    handleCancel(e = null) {
      this.form.resetFields();
      this.visibleData = false;
      this.visibleUpdateData = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    getMock() {
      const targetKeys = [];
      const mockData = [];
      for (let i = 0; i < 20; i++) {
        const data = {
          key: i.toString(),
          title: `content${i + 1}`,
          description: `description of content${i + 1}`,
          chosen: Math.random() * 2 > 1,
        };
        if (data.chosen) {
          targetKeys.push(data.key);
        }
        mockData.push(data);
      }
      this.mockData = mockData;
      this.targetKeys = targetKeys;
    },
    generatePdf() {
      this.$gtag.event("Imp Liste des personnels", {
        event_category: "Impression PDF",
        event_label: "Personnel - ListePersonnels section",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(20);
      doc.text(80, 70, "Liste du personnel");
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      var counter = 1;
      let somme = 0;
      for (let i = 0; i < this.data.length; i++) {
        arr.push(counter);
        // arr.push(this.data[i].matricule);
        arr.push(this.data[i].firstName + " " + this.data[i].lastName);
        arr.push(this.data[i].salary.net);
        somme += this.data[i].salary.net;
        arr.push(this.data[i].position.name);
        arr.push(this.data[i].contract);
        arr.push(this.data[i].phone);
        array.push(arr);
        arr = [];
        counter++;
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        margin: { top: 80 },
        head: [
          [
            "Num",
            "Nom complet",
            "Salaire",
            "Qualification",
            "Type",
            "Téléphone",
          ],
        ],
        body: array,
        foot: [
          [
            "Total des salaires:",
            {
              colSpan: 4,
              content: writtenNumber(somme, { lang: "fr" }) + " DT",
              styles: { halign: "center" },
            },
            somme,
          ],
        ],
      });

      doc.save("Liste_personnel.pdf");
    },
    showModal() {
      this.visibleData = true;
    },
    onChange(e) {},
    onChangeParent(e) {},
    handleCancelImage() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChangeSelect(value) {},
    handleBlur() {},
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}
.span {
  /* display: flex;  */
  flex-direction: row;
}
.space {
  margin-left: 200px;
}
.inp {
  margin-left: 0px;
}
.clearfix {
  float: right;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
